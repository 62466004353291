
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';

import JoinEvent from '../../assets/home/team.png';
import tokenShop from '../../assets/home/store.png';
import tokenTransfer from '../../assets/home/transfer.png';
import tokenDonate from '../../assets/home/donation.png';


import { defaultImg } from "../../global/constants";

import PublicFindDialog from "./components/PublicFindDialog";

import petImg from '../../assets/petPic.jpeg';


import { Link } from "react-router-dom";

import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import liff from '@line/liff'
import SwiperBox from "../../compontents/Swiper";

import bannerImg from '../../assets/pet/welcome.jpg'
import UserApi from "../../services/UserApi";


const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isShowBuyDialog, setShowBuyDialog] = useState(false);
  const closeBuyDialog = () => {
    setShowBuyDialog(false);
  }

  const [totalUser, setTotalUser] = useState("");
  const [totalGainToken, setTotalGainToken] = useState("");


  const [userInfo, setUserInfo] = useState("");

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  let banner = [
    { image: bannerImg },
    { image: bannerImg }
  ]



  const [announcements, setAnnouncements] = useState([]);
  const [adoptPet, setAdoptPet] = useState([]);

  const [isFisrt, doneFisrt] = useState(false)



  const announcementsSearch = async () => {

    try {
      let res = await UserApi.announcementsSearch({ page: 1 })
      setAnnouncements(res.data.data)

    }
    catch (errors) {
      //errorToast(errors?.response?.data?.message || errors.toString())
    }
  }

  const adoptPetSearch = async () => {

    try {
      let res = await UserApi.adoptPetSearch({ page: 1 })
      setAdoptPet(res.data.data)

    }
    catch (errors) {
      // errorToast(errors?.response?.data?.message || errors.toString())
    }
  }




  useEffect(() => {

    announcementsSearch()
    adoptPetSearch()

    if (isFisrt == false) {
      doneFisrt(true)



    }

  }, []);


  return <div className="w-full " >
    <div className="w-full  pt-10">
      <SwiperBox data={banner} />
    </div>

    <div className="session1  pt-8 pb-20">

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
        <div>
          <div className="flex justify-between items-center mb-3">
            <p className="text-black ">最新消息</p>
            <div onClick={()=>navigate('/news')} className="bg-main text-sm rounded-md px-2 py-1 cursor-pointer">更多</div>
          </div>
          {
            announcements.slice(0, 5).map(i => <div onClick={() => navigate('/news/' + String(i.id))} className="  cursor-pointer mb-4 flex gap-4" key={i.title}>
              {/* <img className="w-28 aspect-[1/1] object-cover" src={petImg} /> */}

              <div className="">
                <div>
                  <p className="text-base text-gray-800 font-bold">{i.title}</p>
                  <p className="text-gray-500 text-sm ">{i.createdAt}</p>
                </div>
                {/* <button className=" text-main2 font-bold bg-white rounded-md px-2 py-1">了解更多</button> */}
              </div>

            </div>
            )
          }
        </div>
        <div>
          <div className="flex justify-between items-center mb-3">
            <p className="text-black ">認養專區</p>
            <div onClick={()=>navigate('/adopt')} className="bg-main text-sm rounded-md px-2 py-1 cursor-pointer">更多</div>
          </div>
          <div className=" grid grid-cols-3 gap-3">
            {
              adoptPet.slice(0, 6).map(i => <div onClick={() => navigate('/adopt/' + String(i.id))} className=" cursor-pointer p-2 bg-white " key={i.title}>
                <img className="w-28 aspect-[1/1] object-cover mb-2" src={i.image} />
                <p className="text-base text-gray-800 font-bold ">{i.name}</p>
                
                <p className="text-base text-main2 font-bold ">{i.status =='adopted'?'已領養':'未領養'}</p>
              </div>
              )
            }
          </div>
        </div>


      </div>






    </div>


    <PublicFindDialog itemData={"message"} isShowDialog={isShowBuyDialog} closeDialog={closeBuyDialog}></PublicFindDialog>





  </div>

}

export default Home