import { Dialog } from "@mui/material";
import { useState } from "react";


import axios from "axios";


import { useSelector ,useDispatch} from 'react-redux';

const PublicFindDialog = ({ itemData, isShowDialog, closeDialog }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);
  const [isShowSuccess, setShowSuccess] = useState(false);
  const submit = () => {

    buyItemAPI()
  }


  function buyItemAPI() {

  

    axios.put(`${"apiPrefix"}/v1/browser/shop/item/buy`).then((response) => {
      console.log(response.data);
      

      setShowSuccess(true);
      closeDialog();

      setTimeout(() => {
        setShowSuccess(false);
      }, 2000)
    }).catch(error => {
      console.log(error);
    });
  }


  return !itemData ? "" : (
    <div>
      <Dialog
        PaperProps={{
          style: { borderRadius: 30 },
        }}
        open={isShowDialog}
        onClose={closeDialog}
      >
        <div className="py-5 w-[300px]">
          <div className="flex justify-end">
            <button onClick={closeDialog} className="bg-black bg-opacity-40 w-[22px] py-1 mr-6 rounded-full text-center text-xs font-semibold">
              X
            </button>

          </div>

          


          <div className="flex justify-center mt-[10px]">
            <div className="rounded-full bg-black  py-[6px] px-10 text-xs text-[#F5FF26] font-bold ">  {itemData.name}</div>
          </div>

          <div className="mt-3 text-center px-5 text-[#A6A6A6] text-xs">
            {itemData.desc}
          </div>

          <div className=" mt-4 flex items-center justify-between w-1/2 mx-auto">
            <div className="text-xs">Cost</div>
            <div className="text-sm font-extrabold">{parseInt(itemData.sell_lmt)} LMT</div>
          </div>
          {/* <div className=" mt-3 flex items-center justify-between w-1/2 mx-auto">
            <div className="text-xs">Your LMT</div>
            <div className="text-sm font-extrabold">100 LMT</div>
          </div> */}

          <div className="mt-5 flex justify-center w-full">
            <button onClick={() => {
              submit();
            }} className="text-[18px] font-bold border-2 border-black rounded-full bg-[#F5FF26] px-4 py-1">Confirm</button>
          </div>
        </div>

      </Dialog>

      <Dialog
        PaperProps={{
          style: { borderRadius: 20, background: "transparent" },
        }}
        open={isShowSuccess}
      >
        

      </Dialog>
    </div>
  );
};
export default PublicFindDialog;
