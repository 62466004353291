
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
const JoinTraining = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('您的寶貴意見就是我們成長的動力,歡迎多加利用此表單與我們聯絡交流。');


  const [isFisrt, doneFisrt] = useState(false)

  function sendEmail() {
    window.open('mailto:maotuitui.org@gmail.com');
  }


  useEffect(() => {
    console.log('effect')

    const auth = localStorage.getItem('auth')


    if (isFisrt == false) {
      doneFisrt(true)


    }

  }, []);


  return <div className="session1 py-20" >

    <div className="mb-0.5 w-full flex items-center">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        聯絡我們
      </div>
    </div>

    <div className="text-black">

    </div>

    <div className=" text-black mb-4" dangerouslySetInnerHTML={{ __html: `<div>${point.replaceAll('\n', "<br/>")}</div>` }}>

    </div>

    <button onClick={() => sendEmail()} className="bg-main rounded-md px-4 py-2">寄送電郵</button>


  </div>

}

export default JoinTraining