import { menuData, useGlobalFunc } from "../global/constants"
import { useState,useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";
import { useSelector } from "react-redux";

import pic1Img from '../assets/pet/7.png'

import pic2Img from '../assets/pet/17.png'

const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { i18n } = useTranslation();
    const location = useLocation()

    const {updateMe} = useGlobalFunc()
    const userInfo = useSelector(state => state.userInfo);

    const changeLanguage = () => {
        console.log(i18n.language)
        if (i18n.language == 'zh-TW') {
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage('zh-TW');
        }

    };

    let menu = [
        {
            title: "關於我們",
            path: "/aboutus"
        },
        {
            title: "最新消息",
            path: "/news"
        },
        {
            title: "認養專區",
            path: "/adopt"
        },
        {
            title: "愛心捐款",
            path: "/donation"
        },
        {
            title: "聯絡我們",
            path: "/contactus"
        }
    ]

    const [showStoryMenu, setShowStoryMenu] = useState(false)

    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }


    useEffect(() => {
        updateMe()
        console.log(userInfo)

    }, []);




    return <div className="relative z-50 w-full ">

        <div className=" session1 w-full ">

            <div className="flex justify-between items-center py-2">
                <div className="flex items-center gap-0.5 sm:gap-2 cursor-pointer" onClick={()=>navigate('/')}>
                    <img className="w-10 sm:w-20 h-10 sm:h-20 object-contain" src={pic1Img} alt="" />
                    <p className="text-base sm:text-xl font-bold">社團法人毛腿腿浪愛幸福協會</p>
                </div>
                <div className="text-sm ">
                    {
                        userInfo == null ?
                            <button className="font-bold" onClick={() => navigate('/login')} >登入</button>
                            :
                            <button className="font-bold" onClick={() => navigate('/userInfo')} >個人資料</button>
                    }
                </div>
            </div>

            <div className="flex ">
                <div className="text-xs sm:text-sm rounded-md flex gap-2 bg-main2 rounded-t-xl ml-auto p-2">
                    {
                        menu.map((i, index) => <div className=" flex gap-2 items-center">
                            <Link to={i.path} className="">
                                {i.title}
                            </Link>
                            {
                                index < menu.length - 1 &&
                                <div>
                                    |
                                </div>
                            }
                        </div>
                        )
                    }
                </div>
            </div>
            {
                menu.some(item => location.pathname.includes(item.path)) &&
                <div className="w-full  h-28 bg-cusgray rounded-md flex justify-center items-center text-xl text-white">
                    <img className="w-20 h-20 object-contain" src={pic2Img} alt="" />
                    {menu.find(item => location.pathname.includes(item.path)).title}
                    <img className="w-20 h-20 object-contain" src={pic2Img} alt="" />
                </div>
            }

        </div>



    </div>

}
export default Header