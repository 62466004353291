import {
  BrowserRouter as Router,
  useNavigate,
  useSearchParams

} from "react-router-dom";

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useGlobalFunc } from "../../global/constants";

import { useDispatch } from 'react-redux'
import UserApi from "../../services/UserApi";

const Login = () => {
  const navigate = useNavigate();
  const { updateMe } = useGlobalFunc()
  const [searchParams, setSearchParams] = useSearchParams();



  const clientId = process.env.REACT_APP_CLIENT_ID
  const client_secret =  process.env.REACT_APP_CLIENT_SECRET
  
  let weburl = ""
  const line_redirect_uri = useRef("")

  const [lineURI, setLineURI] = useState("")
  const [loading, setLoading] = useState(false)
  const [sendData, setSendData] = useState([
    {
      title: "帳號",
      content: "",
      placeholder: "帳號"
    },
    {
      title: "密碼",
      content: "",
      placeholder: "密碼"
    },
  ]);
  function sendDataChange(index, value) {

    sendData[index].content = value
    setSendData([...sendData])

  }



  function getLineIDToken() {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("client_id", clientId);
    params.append("client_secret", client_secret);
    params.append("code", searchParams.get('code'));
    params.append("redirect_uri", weburl);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let api_url = "https://api.line.me/oauth2/v2.1/token";

    axios
      .post(api_url, params, config)
      .then((result) => {
        console.log("getLineCode");
        console.log(result.data.id_token);

        getLineID(result.data.id_token);

        // Do somthing
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // Do somthing
      });
  }
  async function getLineID(id_token) {
    console.log(id_token);

    const params = new URLSearchParams();
    params.append("id_token", id_token);
    params.append("client_id", clientId);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let api_url = "https://api.line.me/oauth2/v2.1/verify";

    axios
      .post(api_url, params, config)
      .then((result) => {
        console.log(result);

        usersignin(result.data.name, result.data.sub);

        // Do somthing
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // Do somthing
      });
  }

  async function usersignin(name, idToken) {
    localStorage.setItem('userID', idToken)
    navigate('/userinfo')
    console.log('usersignin')

    console.log(idToken);
    let json = {
      lineToken: idToken,
      name: name
    };


    try {
      let res = await UserApi.signIn(json)
      console.log(res.data.data.token)

      localStorage.setItem('userToken', res.data.data.token)
      updateMe()

      //  navigate('/membercenter/userinfo')

    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  }



  useEffect(() => {
    console.log('effect')

    const userID = localStorage.getItem('userID')
    if (userID != undefined) {
      navigate('/userinfo')
      return
    }



    let port = window.location.port == "" ? "" : ":" + window.location.port;

    weburl =
      window.location.protocol +
      "//" +
      window.location.hostname +
      port +
      window.location.pathname;
    line_redirect_uri.value =
      `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=` +
      weburl +
      "&state=12345abcde&scope=profile%20openid&nonce=09876xyz";

    setLineURI(line_redirect_uri.value)

    console.log(line_redirect_uri.value);

    console.log("getLineCode");
    console.log(searchParams.get('code'));

    //usersignin('line',"Uc38f783dd3426dc41071e27f24971e07")

    if (searchParams.get('code') != undefined) {

      getLineIDToken();
    }

  }, [])




  return <div className="session1 pt-20 max-w-[600px] ">
    {
      !loading ?
        <div>
          <div className="flex mb-10">


          </div>


          <div className="flex justify-center items-center  mb-20">
            <a href={lineURI}>
              <div className="w-60 bg-main rounded-lg p-4 specFont mb-4 md:mb-0 mr-4 shadow-md text-center">Line登入</div>
            </a>

          </div>


        </div>
        :
        <div className="flex">

          <p className="m-auto texl-xl text-black">加載中...</p>

        </div>
    }
  </div>
}

export default Login