

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";



import UserApi from "../../services/UserApi.jsx";
import { useGlobalFunc } from "../../global/constants.js";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const OrderResult = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [data, setData] = useState(null)

  


    useEffect(() => {
        let id = query.get('status')
         console.log(id)

         if (id){
             setData(id)
        }
        else{
            navigate('/')
        }


    }, []);


    return <div className="session1 relative pt-[120px] pb-20 " >



        {
            data && <div className="py-12">
                <div className="w-full h-0.5 bg-sec4"></div>
                {
                     query.get('status') == "0" ?

                        <div className="py-12 w-full">

                            <p className="mb-6  text-center text-black9 text-xl font-bold">愛心捐款</p>
                            <p className="text-black7 text-sm text-center mb-8">
                                
                            付款成功
                            </p>
                            <div className="flex justify-center">
                                <button onClick={() => navigate('/')} className=" btn2 bg-main rounded-full py-2 !border-main1 !text-main1 !min-w-[120px]">前往首頁</button>
                            </div>
                        </div>
                        :
                        <div className="py-12 w-full">

       
                            <p className="mb-6  text-center text-black9 text-xl font-bold">找不到該訂單</p>
                            <p className="text-black7 text-sm text-center mb-8">
                                如有任何疑問也歡迎隨時與我們聯繫<br />
                                將由專人為您服務
                            </p>
                            <div className="flex justify-center ">
                                <button onClick={() => navigate('/')} className=" btn2 bg-main rounded-full py-2 !border-main1 !text-main1 !min-w-[120px]">前往首頁</button>
                            </div>
                        </div>

                }
                <div className="w-full h-0.5 bg-sec4"></div>
            </div>
        }
    </div >
}



export default OrderResult