
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
const JoinTraining = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState(`社團法人毛腿腿浪愛幸福協會：拯救、護理、愛的無私奉獻

  **社團法人毛腿腿浪愛幸福協會：一個多年來的愛心奇蹟**
  
  毛腿腿浪愛幸福協會自Betty愛媽個人救援計劃的發起以來，已經接近18年的歷史。這個協會堅定地奉獻自己，為了拯救和照顧毛孩們，無私地奔走。這個美麗的故事已經幫助了超過3000隻需要幫助的毛孩找到家，也救援近上萬隻犬貓。
  
  ## 拯救：從危難中伸出援手
  
  我們的故事始於愛媽的個人救援行動。當一隻毛孩處於危難之中，我們不是坐視不管，而是積極行動。無論是受傷、受虐，還是其他危機，我們都會迅速行動，確保毛孩得到及時的救助。
  
  ## 完整醫療：呵護每一寸毛孩的健康
  
  對於每一隻受到我們幫助的毛孩，我們提供完整的醫療護理。無論是常規檢查還是緊急手術，我們都會確保毛孩的健康處於最佳狀態。
  
  ## 安置照護：給予溫馨的家
  
  我們不僅關心毛孩的健康，還關心他們的家。在我們的協會中，每一隻毛孩都有一個安全、舒適的地方可以稱之為家。我們努力確保他們不再感到孤獨。
  
  ## 送養篩選：尋找最適合的家庭
  
  我們的使命不僅僅是拯救毛孩，還要確保他們找到一個適合的家。我們進行嚴格的送養篩選，以確保每一位毛孩都能夠找到一個愛他們的家庭。
  
  ## 後續追蹤：不斷關心，直到永遠
  
  我們的關懷不會隨著送養而結束。我們會定期追蹤每一位毛孩，確保他們在新家庭中過得幸福快樂。我們的目標是讓每一位毛孩都能夠浪愛重生。
  
  ## 支持我們，共同創造更多的奇蹟
  
  如果您也被我們的故事和使命所感動，希望支持我們繼續幫助更多的毛孩，請考慮贊助我們。您的支持將有助於我們繼續拯救、護理和愛護那些需要幫助的毛孩。
  點擊此處贊助：[支持我們] ❤️❤️❤️
  
  我們的故事是愛的故事，是拯救的故事，也是希望的故事。感謝您的支持，讓我們一起為毛孩們帶來更多的幸福和愛。
  🚩社團法人毛腿腿浪愛幸福協會🚩
  台內團字第1090054102號
  衛部救字第1121362952號
  `);


  const [isFisrt, doneFisrt] = useState(false)


  useEffect(() => {
    console.log('effect')

    const auth = localStorage.getItem('auth')


    if (isFisrt == false) {
      doneFisrt(true)


      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }

  }, []);


  return <div className="session1 py-20" >

    <div className="mb-0.5 w-full flex items-center">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        關於我們
      </div>

    </div>

    <div className="text-black">

    </div>

    <div className=" text-black" dangerouslySetInnerHTML={{ __html: `<div>${point.replaceAll('\n', "<br/>")}</div>` }}>

    </div>


  </div>

}

export default JoinTraining