
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import petImg from '../../assets/petPic.jpeg';
import UserApi from "../../services/UserApi";
import { useSelector } from "react-redux";

const JoinTraining = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const userInfo = useSelector(state => state.userInfo);

  const [data, setData] = useState(
    {

    }
  )

  const updateData = (i, value) => {
    setData(prevData => ({ ...prevData, [i]: value }));
  };








  const adoptPetGet = async (id) => {

    try {
      let res = await UserApi.adoptPetGet({ id: id })
      setData(res.data.data)

    }
    catch (errors) {
      //errorToast(errors?.response?.data?.message || errors.toString())
    }
  }


  useEffect(() => {
    let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    adoptPetGet(id)

  }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);





  return <div className="session1 py-20" >

    <p className="text-black mb-4">認養專區</p>


    <div className=" p-2 bg-white  ">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <img className="w-full aspect-[1/1] object-cover mb-2" src={data.image} />
        <div >
          <p className="text-base font-bold text-gray-800 ">名稱：{data.name}</p>
          <div className="min-h-[50px]" dangerouslySetInnerHTML={{ "__html": data.details }}>

          </div>
          {/* <p className="text-base text-gray-500 ">品種：{data.name}</p>
          <p className="text-base text-gray-500 ">種類：{data.name}</p>
          <p className="text-base text-gray-500 ">性別：{data.name}</p>
          <p className="text-base text-gray-500 ">體型：{data.name}</p>
          <p className="text-base text-gray-500 ">毛色：{data.name}</p>
          <p className="text-base text-gray-500 ">年紀：{data.name}</p>
          <p className="text-base text-gray-500 ">結紮：{data.name}</p>
          <p className="text-base text-gray-500 ">所在地：{data.name}</p>
          <p className="text-base text-gray-500 ">刊登日期：{data.name}</p> */}

          {data.status == 'adopted' ?
            <p className="text-base text-white w-max font-bold bg-main  p-2 rounded-md ">已領養</p>
            :
            <div>
              {
                userInfo ?
                  <div>

                    <div className=" bg-main  p-2 rounded-md">
                      <div>聯絡資訊
                      </div>
                      <div className=" whitespace-pre-line">
                        {data.contact}
                      </div>
                    </div>

                  </div>
                  :
                  <div className="bg-main p-2 rounded-md">
                    <div>請先登入會員查看聯絡資訊</div>
                    <button className="bg-white p-2 rounded-lg">登入</button>
                  </div>
              }
            </div>
          }
        </div>
      </div>

      <p className="text-black mb-2">詳細介紹</p>
      <div dangerouslySetInnerHTML={{ "__html": data.details }}>

      </div>

    </div>





  </div >

}

export default JoinTraining