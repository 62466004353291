
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect,useRef } from 'react';
import {
    BrowserRouter as Router,
    useNavigate,
    Link

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Select from 'react-select';
import { getUserInfoById } from '../../global/constants.js'

import Compressor from 'compressorjs';

import default_icon from '../../assets/upload_img.jpg';

const EditUserInfo = () => {
    const navigate = useNavigate();


    const sexOption = [
        { value: '不詳', label: '不詳' },
        { value: '男', label: '男' },
        { value: '女', label: '女' },
    ]

    const userID = localStorage.getItem('userID')

    const [sex, setSex] = useState(sexOption[0].value)


    const [compressedFile, setCompressedFile] = useState(null);

    function callInputImg() {
        let call = document.querySelector('#inputimg')
        call.click()
    }

    const handleCompressedUpload = (e) => {
        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            convertSize: 2000000,
            success: (result) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server. 
                console.log(result)
                setCompressedFile(URL.createObjectURL(result))

                var reader = new FileReader();
                reader.readAsDataURL(result); 
                reader.onloadend = function() {
                var base64data = reader.result;                
                //console.log(base64data);
                base64data = base64data.split(',')[1]
                imgBase64.current = base64data

                console.log(base64data)
                
                }
            },
        });
    };
    const imgBase64 = useRef('')


    const [sendData, setSendData] = useState([
        {
            title: "名稱",
            content: "",
            placeholder: "名稱"
        },
        {
            title: "種類",
            content: "",
            placeholder: "種類"
        },
        {
            title: "顏色",
            content: "",
            placeholder: "顏色"
        },
        {
            title: "體重",
            content: "",
            placeholder: "體重"
        },
        {
            title: "寵物介紹",
            content: "",
            placeholder: "寵物介紹"
        },
    ]);
    function sendDataChange(index, value) {

        sendData[index].content = value
        setSendData([...sendData])

    }

    function updateUserInfo() {
        updateIcon()
        let json = {
            "token": localStorage.getItem('userID'),
            "nickName": sendData[0].content,
            "name": sendData[1].content,
            "idCardNumber": sendData[3].content,
            "sex": 0,
            "address": sendData[4].content,
            "phone": sendData[2].content
        }
        const api_url = process.env.REACT_APP_API_URL + "/user/update"
        console.log(json)

        axios.post(api_url, json)
            .then(response => {

                console.log(response.data)
                navigate('userinfo')


            })
            .catch(error => {
                console.log(error)
                alert('更新失敗')

            });
    }

    function updateIcon() {
        let json = {
            "token": localStorage.getItem('userID'),
            "icon" : imgBase64.current
        }
        const api_url = process.env.REACT_APP_API_URL + "/user/updateIcon"
        console.log(json)

        axios.post(api_url, json)
            .then(response => {

            })
            .catch(error => {
                console.log(error)

            });
    }

    async function getInitData() {
        let data = await getUserInfoById(userID)
        console.log(data)



    }


    useEffect(() => {
        console.log('effect')

        getInitData()
    }, [])




    return <div className="session1 pt-20 max-w-[600px]">

            <div className="mb-4 w-full flex items-center">
                    <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                    <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                        修改愛寵資料
                    </div>

            </div>

        <div className="w-60 mx-auto mb-2">
        <div className="w-full ratio-1/1 rounded-md overflow-hidden shadow-md">
            <div className="ratio-in  pointer-events-none ">
                <img src={compressedFile ? compressedFile : default_icon} className=" absolute  left-0 top-0 w-full h-full object-cover"
                />
            </div>
            <input
                className="hidden"
                id="inputimg"
                accept="image/*"
                type="file"
                onChange={(event) => handleCompressedUpload(event)}
            />
            <div
                type="button"
                className="absolute top-0 left-0 w-full h-full bg-text cursor-pointer"
                onClick={() => callInputImg()}
            >
            </div>


        </div>
        </div>
        <p className="text-center text-black mb-4">選擇圖片</p>



        <div className="flex ">

            <div className='w-full '>

                <div className="mb-0.5 w-full flex items-center">
                    <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                    <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                        性別
                    </div>

                </div>


                <Select
                    defaultValue={sexOption[0]}
                    options={sexOption}
                    className="basic-select mb-4 text-black border-gray-700"
                    classNamePrefix="select"
                    onChange={(e) => setSex(e.value)}
                />

                {
                    sendData.map((i, index) =>
                        <div className='w-full' key={i.title}>
                            <div className="mb-0.5 w-full flex items-center">
                                <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                                <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                                    {i.title}
                                </div>

                            </div>

                            <div className=" overflow-hidden rounded-md  relative md:flex bg-white border-solid border border-[#CCCCCC]  w-full mb-6">

                                <div className="w-full  text-base opacity-80 " >
                                    <input id="inputID" className='px-2 py-[5px] w-full   placeholder-gray-400 text-black' type="text" placeholder={i.placeholder} onChange={(e) => sendDataChange(index, e.target.value)} />
                                </div>

                            </div>
                        </div>
                    )}


            </div>


        </div>


        <div className="grid grid-cols-2 justify-between gap-3 py-4">
            <button className="w-full bg-main rounded-xl p-4 specFont  shadow-md" onClick={() => updateUserInfo()} >修改</button>
            <Link to="/" className="w-full text-center bg-gray-400 rounded-xl p-4 specFont shadow-md" >取消</Link>
        </div>




    </div>
}

export default EditUserInfo