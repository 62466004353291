
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import UserApi from "../../services/UserApi";
import { Pagination } from "@mui/material";

const JoinTraining = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    console.log(value)
    setPage(value);
    adoptPetSearch(value)
  };


  const [dataList, setDataList] = useState(false)



  const adoptPetSearch = async (page) => {

    try {
      let res = await UserApi.adoptPetSearch({ page: page })
      setDataList(res.data.data)
      setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

    }
    catch (errors) {
      //errorToast(errors?.response?.data?.message || errors.toString())
    }
  }



  useEffect(() => {


    adoptPetSearch(1)



  }, []);


  return <div className="session1 py-20" >

    <div className="mb-0.5 w-full flex items-center">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        認養專區
      </div>
    </div>


    <div className=" grid grid-cols-3 gap-3">
      {
        dataList && dataList.map(i => <div onClick={() => navigate(String(i.id))} className=" cursor-pointer p-2 bg-white " key={i.id}>
          <img className="w-28 aspect-[1/1] object-cover mb-2" src={i.image} />
          <p className="text-base text-gray-800 font-bold ">{i.name}</p>
          <p className="text-base text-main2 font-bold ">{i.status == 'adopted' ? '已領養' : '未領養'}</p>
        </div>
        )
      }
    </div>

    {
      dataList != null ?
        <>
          {
            dataList.length == 0 ?
              <div className="  text-center text-gray-400 text-lg w-full font-bold  mb-16 ">
                <div>暫無紀錄</div>
              </div> : ""

          }

          <div className="flex w-full  mt-4 mb-2">
            <div className="mx-auto">
              <Pagination count={totalPage} page={page} onChange={handleChange} />
            </div>
          </div>
        </>
        : ""
    }

  </div>

}

export default JoinTraining