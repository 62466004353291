import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import Header from './compontents/Header'
import Footer from './compontents/Footer'



import UserInfo from "./pages/UserInfo"
import EditPetInfo from "./pages/EditPetInfo"


import AboutUs from "./pages/AboutUs"
import ContactUs from "./pages/ContactUs"

import News from "./pages/News"
import NewsDetail from "./pages/NewsDetail"

import Adopt from "./pages/Adopt"
import AdoptDetail from "./pages/AdoptDetail"

import Donation from "./pages/Donation"
import OrderResult from "./pages/OrderResult"

import Login from "./pages/Login";

import Home from "./pages/Home";

import "./i18n"

import { useTranslation } from "react-i18next";
import { t } from "react-i18next";


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import RouteChange from './compontents/RouteChange'

import { Provider } from "react-redux";
import { store } from "./model/store.js";

import bg from '../src/assets/pet/bg.png'
import Loading from './compontents/Loading';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter >

      <ToastContainer closeButton={false} icon={false} position="top-center" toastClassName="!rounded !overflow-hidden !bg-white !bg-opacity-90  !backdrop-blur-sm mb-1 " className=" !p-2  " bodyClassName={() => "flex items-center text-black9"} />
      <div className='grid grid-cols-1 w-full h-full min-h-screen max-w-[1024px]  mx-auto'>
        <ScrollToTop />
        <RouteChange />


        <div className='w-full  grid-cols-none  relative'>
          <img className=' absolute w-full h-full object-cover ' src={bg} />
          <div className='w-full relative'>
            <Loading />
            <Header />
            <Routes >
              <Route path="*" element={<Home />}></Route>
              <Route path="/home" element={<Home />}></Route>


              <Route path="/userinfo" element={<UserInfo />}></Route>
              <Route path="/editpetinfo" element={<EditPetInfo />}></Route>
              {/* <Route path="/pet/:id" element={<PetDetail />}></Route> */}
              <Route path="/aboutus" element={<AboutUs />}></Route>
              <Route path="/contactus" element={<ContactUs />}></Route>


              <Route path="/donation" element={<Donation />}></Route>
              <Route path="/orderresult" element={<OrderResult />}></Route>

              <Route path="/news" element={<News />}></Route>
              <Route path="/news/:id" element={<NewsDetail />}></Route>

              <Route path="/adopt" element={<Adopt />}></Route>
              <Route path="/adopt/:id" element={<AdoptDetail />}></Route>


              <Route path="/login" element={<Login />}></Route>

            </Routes>
          </div>
        </div>
        {/* <Footer  /> */}
      </div>

    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
