
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import { getUserInfo, gUserInfo } from "../../global/constants"

import { useSelector } from "react-redux";

import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";

import joinEventIcon from '../../assets/home/team.png';


import logoImg from '../../assets/pet/2.png'

import pic2Img from '../../assets/pet/17.png'



import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Swiper from '../../compontents/Swiper'

const UserInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const userInfo = useSelector(state => state.userInfo);

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const funcList = [
    {
      title: '活動紀錄',
      path: '/eventrecord',
      img: joinEventIcon
    },


  ]

  const toolList = [
    {
      title: '聯絡人',
      path: ''
    },

  ]



  const [isFisrt, doneFisrt] = useState(false)

  function logout() {
    localStorage.removeItem('userID')
    window.location.reload()
  }




  useEffect(() => {
    console.log('effect')

    const userID = localStorage.getItem('userID')
    if (userID == undefined) {
      navigate('/login')
    }



  }, []);


  return <div className="session1 pt-10" >

    {
      userInfo &&
      <div className="w-full mx-auto relative flex items-center mb-10">

        <div className=" overflow-hidden shadow-md mr-4 flex-none relative w-28 h-28  bg-main rounded-full mx-auto mb-1">
          <div className="h-full ">
            <img className="w-full h-full object-cover" src={logoImg} />

          </div>

        </div>
        <div className="w-full ">
          <p className="text-sm text-gray-700">編號: {userInfo.systemId || ''}</p>
          <p className="text-gray-800 text-lg font-bold">暱稱: {userInfo.name != null ? userInfo.name : '有愛心的人'}</p>



        </div>


      </div>
    }




    {/* 

    <p className=" text-maintext sm:text-xl mt-10 mb-4">常用功能</p>
    <div className="mb-10 grid grid-cols-3 gap-3 justify-between flex-wrap max-w-[800px] mx-auto ">
      {
        funcList.map(i => <Link to={i.path} key={i.title}>
          <div className="ratio-1/1 bg-main rounded-full mx-auto mb-1">
            <div className="ratio-in">
            <div className="w-full h-full flex">
                <img className="w-3/5 m-auto" src={i.img}/>
                </div>

            </div>

          </div>
          <p className="text-center text-maintext text-sm sm:text-xl">{i.title}</p>
        </Link>
        )

      }

    </div>


    <p className="hidden text-maintext sm:text-xl mt-10 mb-4 ">更多工具</p>
    <div className="hidden grid grid-cols-3 sm:grid-cols-3 gap-5 justify-between flex-wrap max-w-[800px] mx-auto ">
    {
        toolList.map(i => <Link to={i.path} key={i.title}>
          <div className="ratio-1/1 bg-main rounded-full mx-auto mb-1">
            <div className="ratio-in">

            </div>

          </div>
          <p className="text-center text-maintext sm:text-xl">{i.title}</p>
        </Link>
        )

      }

    </div> */}
    <div onClick={() => logout()} className=" cursor-pointer text-black text-center justify-between bg-main bg-opacity-20 p-4 rounded-lg my-10">
      登出
    </div>




  </div>

}

export default UserInfo