
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { doc, format } from "prettier";
import { LazyResult } from "postcss";

import petImg from '../../assets/petPic.jpeg';
import lineQRcodeImg from '../../assets/lineQRcode.png';

import { useSelector } from "react-redux";
import UserApi from "../../services/UserApi";
import { useGlobalFunc } from "../../global/constants";

const Donation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userInfo = useSelector(state => state.userInfo);
  const { successToast, errorToast, setLoading } = useGlobalFunc()


  const [donationValue, setDonationValue] = useState(100)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const [data, setData] = useState(
    {

    }
  )

  const updateData = (i, value) => {
    setData(prevData => ({ ...prevData, [i]: value }));
  };


  const [isFisrt, doneFisrt] = useState(false)

  function donationAction(e) {
    e.preventDefault();

    if (userInfo) {
      if (donationValue < 30) {
        alert('(捐款)最低金額為NT$100')
        return
      }

      setupTP()
      return

    }
    else {
      alert('請先登入')
      navigate('/login')
    }
  }

  function setupTP() {
    // window.TPDirect.card.onUpdate(function (update) {
    //   console.log(update)
    // })
    setLoading(true)


    window.TPDirect.card.getPrime(function (result) {
      if (result.status !== 0) {
        console.error('getPrime error')
        setLoading(false)
        return
      }
      console.log(result)
      var prime = result.card.prime
      console.log('getPrime success: ' + prime)
      donateCreate(prime)
    })
  }

  const donateCreate = async (prime) => {
    console.log(prime)

    let json = {
      "prime": prime,
      "amount": Number(donationValue),
      "phoneNumber": phone,
      "name": name,
      "email": email,
      'redirectUrl':window.location.origin +'/orderresult'
    }

    try {
      let res = await UserApi.donateCreate(json)

      window.location.href = res.data.data

      setDonationValue(100)
      setPhone('')
      setName('')
      setEmail('')


    }
    catch (errors) {
      setLoading(false)
      errorToast(errors?.response?.data?.message || errors.toString())
    }
    finally {
      
    }
  }


  useEffect(() => {


    var script = document.createElement('script')
    script.type = 'type/javascript'
    script.async = true
    script.src = "https://js.tappaysdk.com/sdk/tpdirect/v5.17.1"
    document.head.appendChild(script)

    const APP_ID = process.env.REACT_APP_TAPPAY_APP_ID
    const APP_KEY = process.env.REACT_APP_TAPPAY_APP_KEY


    window.TPDirect.setupSDK(APP_ID, APP_KEY, 'production') //  sandbox / production



    var defaultCardViewStyle = {
      color: 'rgb(0,0,0)',
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '300',
      errorColor: 'red',
      placeholderColor: ''
    }
    // 預設不戴第三個參數的話, 是會必須填入 CCV
    window.TPDirect.card.setup('#cardview-container', defaultCardViewStyle, {
      isUsedCcv: true,
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 10,
        endIndex: 10
      }
    })

    // 帶入第三個參數, config.isUsedCcv 為以下兩種參數代表不同意思
    // false 為 CCV 非必填
    // true 為 CCV 必填

    // 卡號輸入完畢，驗證成功後會將設定的起始位元至結束位元以 * 做遮蔽
    // 若遮蔽後，點擊到信用卡欄位做變更(輸入或刪除卡號)，會將遮蔽卡號 * 清除
    // 若結束位元超過信用卡卡號長度，會以信用卡卡號長度上限做遮蔽

    // isMaskCreditCardNumber: boolean
    // 設定是否要啟用遮蔽卡號功能
    // beginIndex: Int
    // 起始遮蔽卡號位元
    // endIndex: Int
    // 結束遮蔽卡號位元

    console.log(window.TPDirect)

  }, []);


  return <div className="session1 py-20" >


    <div className="mb-0.5 w-full flex items-center">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        愛心捐款
      </div>
    </div>


    <div className=" p-2   ">
      <form onSubmit={(e) => donationAction(e)}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <img className="w-full aspect-[1/1] object-cover mb-2" src={petImg} />
          <div >
            <p >捐款項目：</p>
            <p className="mb-4 text-xl">飼料營養品費用</p>


            <p>捐款金額：</p>
            <input required type="number" className=" w-full border-solid border-2 border-black p-2" value={donationValue} onChange={(e) => setDonationValue(e.target.value)} />
            <p className="text-gray-500">(捐款)最低金額為NT$100</p>

            <p className=" mt-4">付款方式(信用卡)：</p>


            <div className="mb-4 bg-white w-full border-solid border-2 border-black p-2">
              <div id="cardview-container"></div>
            </div>


            <p>持卡人姓名：</p>
            <input required type="text" className="mb-4 w-full border-solid border-2 border-black p-2" value={name} onChange={(e) => setName(e.target.value)} />
            <p>電話號碼：</p>
            <input required type="number" className="mb-4 w-full border-solid border-2 border-black p-2" value={phone} onChange={(e) => setPhone(e.target.value)} />
            <p>電子郵箱：</p>
            <input required type="email" className="mb-8 w-full border-solid border-2 border-black p-2" value={email} onChange={(e) => setEmail(e.target.value)} />



            <input type="submit" value="捐款" className="bg-main cursor-pointer rounded-md px-4 py-2" />





            <div className="mt-20 text-sm text-gray-500">
              * 如有交易上的問題請聯繫客服，我們會盡快協助處理
              <img className="w-40 object-contain mt-2" src={lineQRcodeImg} />
            </div>

          </div>
        </div>
      </form>

      {/* <p className="text-black mb-4">詳細介紹</p>
      <div dangerouslySetInnerHTML={{ "__html": data.content }}>

      </div> */}

    </div>





  </div >

}

export default Donation