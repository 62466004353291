import api from './api.jsx'

export default class UserApi {
    static signIn(data) {
        return api({
            url: '/auth/signIn',
            method: "POST",
            data
        })
    }

    static me() {
        return api({
            url: '/auth/me',
            method: "GET",

        })
    }



    static announcementsSearch(data) {
        return api({
            url: `/announcements/search?pageIndex=${data.page}&pageSize=10`,
            method: "GET",
        })
    }

    static announcementsGet(data) {
        return api({
            url: `/announcements/get?id=${data.id}`,
            method: "GET",
        })
    }

    static adoptPetSearch(data) {
        return api({
            url: `/adopt/pet/search?pageIndex=${data.page}&pageSize=10`,
            method: "GET",
        })
    }

    static adoptPetGet(data) {
        return api({
            url: `/adopt/pet/get?id=${data.id}`,
            method: "GET",
        })
    }

    // static sendTapPay(data) {
    //     return api({
    //         url: `/adopt/pet/get?id=${data.id}`,
    //         method: "GET",
    //     })
    // }

    static donateCreate(data) {
        return api({
            url: `/donate/create`,
            method: "POST",
            data
        })
    }
















    static cartAdd(data) {
        return api({
            url: '/auth/cart/add',
            method: "POST",
            data
        })
    }
    static cartRemove(data) {
        return api({
            url: '/auth/cart/remove',
            method: "DELETE",
            data
        })
    }
    static cartGet(data) {
        return api({
            url: '/auth/cart/get',
            method: "GET",
            data
        })
    }


    static productCategorySearch() {
        return api({
            url: `/product/category/search`,
            method: "GET",
        })
    }

    static productSearch(data) {
        return api({
            url: `/product/search?pageIndex=${data.pageIndex}&pageSize=10&subcategoryId=${data.subcategoryId}`,
            method: "GET",
        })
    }

    static productGet(data) {
        return api({
            url: `/product/get?id=${data.id}`,
            method: "GET",
        })
    }

    static parameterGet() {
        return api({
            url: `/parameter/get`,
            method: "GET",
        })
    }

    static teamGet() {
        return api({
            url: `/auth/team/get`,
            method: "GET",
        })
    }

    static orderPay(data) {
        return api({
            url: `/order/pay`,
            method: "POST",
            data
        })
    }

    static orderSearch(data) {
        return api({
            url: `/order/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }

    static orderGet(data) {
        return api({
            url: `/order/get?tradeNo=${data.id}`,
            method: "GET",
        })
    }





    static productPricedSearch(data) {
        return api({
            url: `/product/priced/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static productPricedGet(data) {
        return api({
            url: `/product/priced/get?id=${data.id}`,
            method: "GET",
        })
    }


    static updateMe(data) {
        return api({
            url: '/auth/me/update',
            method: "PATCH",
            data
        })
    }

    static getOtp(data) {
        return api({
            url: '/auth/getOtp',
            method: "POST",
            data
        })
    }


    static favoriteAdd(data) {
        return api({
            url: `/auth/favorite/add`,
            method: "POST",
            data: data
        })
    }
    static favoriteSearch(data) {
        return api({
            url: `/auth/favorite/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }
    static favoriteDelete(data) {
        return api({
            url: `/auth/favorite/delete`,
            method: "DELETE",
            data: data
        })
    }

    static activityApply(data) {
        return api({
            url: `/activity/apply`,
            method: "POST",
            data: data
        })
    }
    static activitySearch(data) {
        return api({
            url: `/activity/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static activityGet(data) {
        return api({
            url: `/activity/get/?id=${data.id}`,
            method: "GET"
        })
    }

    static notificationRead(data) {
        return api({
            url: `/notification/read`,
            method: "POST",
            data: data
        })
    }
    static notificationSearch(data) {
        return api({
            url: `/notification/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }


    static negotiationApply(data) {
        return api({
            url: `/negotiation/apply`,
            method: "POST",
            data: data
        })
    }
    static negotiationSearch(data) {
        return api({
            url: `/negotiation/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static negotiationGet(data) {
        return api({
            url: `/negotiation/get/?id=${data.id}`,
            method: "GET"
        })
    }

    static negotiationDispatchSearch(data) {
        return api({
            url: `/negotiation/dispatch/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static negotiationDispatchGet(data) {
        return api({
            url: `/negotiation/dispatch/get/?id=${data.id}`,
            method: "GET"
        })
    }

    static performanceGet(data) {
        return api({
            url: `/performance/get`,
            method: "POST",
            data
        })
    }






    static manageProductPricedSearch(data) {
        return api({
            url: `/auth/product/priced/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }

    static manageProductAuctionSearch(data) {
        return api({
            url: `/auth/product/auction/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }

    static settlementRecord(data) {
        return api({
            url: `/auth/settlement/record/get?dateStr=${data.dateStr}`,
            method: "GET",
        })
    }

    static orderRemittanceCreate(data) {
        return api({
            url: `/order/remittance/create`,
            method: "POST",
            data: data
        })
    }

    static lineNotifyToken(data) {
        return api({
            url: `/auth/me/line/notifyToken`,
            method: "PATCH",
            data: data
        })
    }
}