import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"

import "./style.css";

import axios from "axios";

// import required modules
import { Autoplay, Pagination } from "swiper";


export default function SwiperBox(props) {

  const [banner, setBanner] = useState([])

  function getBanner() {

    let json = {
    }
    const api_url = process.env.REACT_APP_API_URL + "/banner/get"
    console.log(api_url)

    axios.post(api_url, json)
      .then(response => {

        setBanner(response.data.data)


      })
      .catch(error => {


      });
  }

  useEffect(() => {
    setBanner(props.data)
    //getBanner()

  }, []);

  return (
    <>
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={0}
        grabCursor={true}
        loop={true}
        //pagination={{
        //clickable: true,
        //}}
        autoplay={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper autoplay rounded-lg overflow-hidden bg-main"
      >
        {
          banner.map((i, index) => <SwiperSlide key={index} className="overflow-hidden"><img src={i.image} className=" object-cover" /></SwiperSlide>)
        }



      </Swiper>
    </>
  );
}
