
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import UserApi from "../../services/UserApi";
const JoinTraining = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const [data, setData] = useState(
    {

    }
  )

  const updateData = (i, value) => {
    setData(prevData => ({ ...prevData, [i]: value }));
  };



  const announcementsGet = async (id) => {

    try {
      let res = await UserApi.announcementsGet({ id: id })
      setData(res.data.data)

    }
    catch (errors) {
      //errorToast(errors?.response?.data?.message || errors.toString())
    }
  }


  useEffect(() => {
    let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    announcementsGet(id)

  }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);



  return <div className="session1 py-20" >

    <p className="text-black mb-2">最新消息</p>

    <p className="text-lg text-gray-800 font-bold ">{data.title}</p>
    <p className="text-gray-500  text-sm mb-4">{data.createdAt}</p>
    <div dangerouslySetInnerHTML={{'__html':data.content}}>

    </div>



  </div>

}

export default JoinTraining