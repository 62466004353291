
const initState = {
  menuItemData: [
    "1",
    "2",
  ],
  userPoint: 0,
  userInfo: null,
  loading: false,

  menu: null,

  selectDialog: {
    isOpen: false
  },
  actionDialog: {
    isOpen: false
  },
  alertDialog: {
    isOpen: false,
    content: "",
  },

};

const itemReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      const menuItemCopy = state.menuItemData.slice();
      return { menuItemData: [action.payload.itemNew].concat(menuItemCopy) };
    }
    case 'CLEAN_ITEM': {
      return { menuItemData: [] };
    }

    case 'UPDATE_LOADING': {
      return { ...state, loading: action.payload.loading };
    }
    
    case 'UPDATE_USERINFO': {
      return { ...state, userInfo: action.payload.userInfo, userPoint: action.payload.userPoint };
    }
    
    default:
      return state;
  }
};


export { itemReducer };